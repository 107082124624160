<template>
  <div class="main-layout__content">
    <div class="content">
      <h1 class="page-title">Your payment was canceled</h1>
      <div class="container text-center">
        <b-button to="/subscriptions" variant="primary">Return to Subscriptions</b-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>